import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanLoad,
  Route,
  UrlSegment,
  CanActivateChild,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';

import { take, map } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private _authService: AuthService, private _router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._authService.getCurrentUserData().pipe(
      take(1),
      map((user) => {
        const isAuth = !!user;
        if (isAuth) {
          return true;
          // if (!user.email_verified_at) {
          //   // this._router.navigate(['/verify-email']);
          //   this._router.navigate(['/']);
          //   return false;
          // } else {
          //   return true;
          // }
        } else {
          // this._router.navigate(['/sign-in']);
          this._router.navigate(['/home']);
          return false;
        }
      })
    );
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.canActivate(route, state);
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._authService.getCurrentUserData().pipe(
      take(1),
      map((user) => {
        const isAuth = !!user;
        if (isAuth) {
          return true;
          // if (!user.email_verified_at) {
          //   // this._router.navigate(['/verify-email']);
          //   this._router.navigate(['/']);
          //   return false;
          // } else {
          //   return true;
          // }
        } else {
          // this._router.navigate(['/sign-in']);
          this._router.navigate(['/home']);
          return false;
        }
      })
    );
  }
}
