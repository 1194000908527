import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StockDetailInterface } from 'src/app/interfaces/stock-investment/stock-detail-interface';
import { StockListItemInterface } from 'src/app/interfaces/stock-investment/stock-list-item-interface';
import { UserStockInvestmentInterface } from 'src/app/interfaces/stock-investment/user-stock-investment-interface';
import { SystemService } from '../system.service';

@Injectable({
  providedIn: 'root',
})
export class StockInvestmentService {
  private predefinedStocks: StockListItemInterface[] = [
    {
      '1. symbol': 'AAPL',
      '2. name': 'Apple',
      '8. currency': 'USD',
    },
    {
      '1. symbol': 'AMZN',
      '2. name': 'Amazon.com Inc',
      '8. currency': 'USD',
    },
    {
      '1. symbol': 'MSFT',
      '2. name': 'Microsoft Corporation',
      '8. currency': 'USD',
    },
    {
      '1. symbol': 'GOOGL',
      '2. name': 'Alphabet Inc - Class A',
      '8. currency': 'USD',
    },
    {
      '1. symbol': 'FB',
      '2. name': 'Facebook Inc - Class A',
      '8. currency': 'USD',
    },
    {
      '1. symbol': 'TSLA',
      '2. name': 'Tesla Inc',
      '8. currency': 'USD',
    },
    {
      '1. symbol': 'BABA',
      '2. name': 'Alibaba Group Holding Ltd',
      '8. currency': 'USD',
    },
  ];

  private _stocksList = new BehaviorSubject<StockListItemInterface[]>([]);

  private _userStocks = new BehaviorSubject<UserStockInvestmentInterface[]>([]);

  constructor(
    private _systemService: SystemService,
    private _http: HttpClient
  ) {}

  getPredefinedStocks() {
    return this.predefinedStocks;
  }

  // third part API requests
  searchForStocks(searchPrefix: string = '') {
    const data = {
      searchPrefix,
    };

    return this._http.post<any>(
      this._systemService.getStockAPIRootURL() + '/search-stocks',
      data
    );
  }

  getStocksList() {
    return this._stocksList;
  }

  setStocksList(data: StockListItemInterface[]) {
    this._stocksList.next(data);
  }

  fetchStockDetail(stock_symbol: string) {
    const data = {
      stock_symbol,
    };

    return this._http.post<any>(
      this._systemService.getStockAPIRootURL() + `/stock-details`,
      data
    );
  }

  fetchStockCompanyOverview(stock_symbol: string) {
    const data = {
      stock_symbol,
    };

    return this._http.post<any>(
      this._systemService.getStockAPIRootURL() + '/stock-company-overview',
      data
    );
  }

  // app backend stocks api requests
  fetchUserStockInvestments() {
    return this._http.get<any>(this._systemService.getApiRootURL() + `/stocks`);
  }

  getUserStockInvestments() {
    return this._userStocks;
  }

  setUserStockInvestments(data: UserStockInvestmentInterface[]) {
    this._userStocks.next(data);
  }

  fetchUserStockInvestmentDetail(stock_investment_uniqid: string) {
    return this._http.get<any>(
      this._systemService.getApiRootURL() + `/stocks/${stock_investment_uniqid}`
    );
  }

  addUserStockInvestmentDetail(stock: UserStockInvestmentInterface) {
    return this._http.post<any>(
      this._systemService.getApiRootURL() + `/stocks`,
      stock
    );
  }

  editUserStockInvestmentDetail(stock: UserStockInvestmentInterface) {
    return this._http.put<any>(
      this._systemService.getApiRootURL() +
        `/stocks/${stock.stock_investment_uniqid}`,
      stock
    );
  }

  deleteUserStockInvestmentDetail(stock: UserStockInvestmentInterface) {
    return this._http.delete<any>(
      this._systemService.getApiRootURL() +
        `/stocks/${stock.stock_investment_uniqid}`
    );
  }
}
