import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CoinInterface } from 'src/app/interfaces/coin/coin';
import { ExternalServerCoinInterface } from 'src/app/interfaces/coin/external-server-coin-interface';
import { SystemService } from 'src/app/services/system.service';

@Injectable({
  providedIn: 'root',
})
export class CoinService {
  private _userCoins = new BehaviorSubject<CoinInterface[]>(null);

  private _external_server_available_coins = new BehaviorSubject<
    ExternalServerCoinInterface[]
  >(null);

  private _external_server_specific_coins = new BehaviorSubject<
    ExternalServerCoinInterface[]
  >(null);

  coinRankingBase = this._systemService.getCoinRankingRootURL(); // production

  constructor(
    private _http: HttpClient,
    private _systemService: SystemService
  ) {}

  fetchUserCoins() {
    return this._http.get(this._systemService.getApiRootURL() + '/user/coins');
  }

  getUserCoins() {
    return this._userCoins;
  }

  setUserCoins(coins: CoinInterface[]) {
    this._userCoins.next(coins);
  }

  fetchUserCoin(localCoinUniqueId) {
    return this._http.get(
      this._systemService.getApiRootURL() + '/user/coins/' + localCoinUniqueId
    );
  }

  addUserCoin(data: CoinInterface) {
    return this._http.post(
      this._systemService.getApiRootURL() + '/user/coins',
      data
    );
  }

  updateUserCoin(data: CoinInterface, localCoinUniqueId) {
    return this._http.put(
      this._systemService.getApiRootURL() + '/user/coins/' + localCoinUniqueId,
      data
    );
  }

  deleteUserCoin(localCoinUniqueId) {
    return this._http.delete(
      this._systemService.getApiRootURL() + '/user/coins/' + localCoinUniqueId
    );
  }

  // External Server Coins APIS
  fetchAvailableExternalServerCoinsData(
    prefix = null,
    currency = 'USD',
    time_period = '24h'
  ) {
    const data = { prefix, currency, time_period };

    return this._http.post(`${this.coinRankingBase}/coins`, data);
  }

  getAvailableExternalServerCoinsData() {
    return this._external_server_available_coins;
  }

  setAvailableExternalServerCoinsData(coins: ExternalServerCoinInterface[]) {
    this._external_server_available_coins.next(coins);
  }

  getExternalCoinDetail(externalCoinId, currency = 'USD', time_period = '24h') {
    const data = { externalCoinId, currency, time_period };
    return this._http.post(
      `${this.coinRankingBase}/coin/${externalCoinId}`,
      data
    );
  }

  fetchSpecificCoinsDataFromExternalServer(
    externalCoinsIds: string,
    currency = 'USD',
    time_period = '24h'
  ) {
    const data = { externalCoinsIds, currency, time_period };
    return this._http.post(`${this.coinRankingBase}/coins-base`, data);
  }

  getSpecificCoinsDataFromExternalServer() {
    return this._external_server_specific_coins;
  }

  setSpecificCoinsDataFromExternalServer(coins: ExternalServerCoinInterface[]) {
    this._external_server_specific_coins.next(coins);
  }
}
